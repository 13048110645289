.main-bar {
	margin: auto;
	max-width: 800px;
	width: 80%;

	&__progress {
		outline-width: 8px;
		outline-color: $white;
		outline-style: solid;
		height: 10px;
	}

	&__box {
		display: flex;
		justify-content: space-between;
		margin-top: -32px;
	}

	&__item {
		width: 50px;
		height: 50px;
		line-height: 50px;
		border: 1px solid $gray-100;
		border-radius: 50%;
		text-align: center;
		position: relative;
		background: $white;
		color: $gray-300;

		.icx {
			font-size: 1.5em !important;
			line-height: 2;
		}

		&.active {
			border: 1px solid $blue;

			.icx {
				color: $blue;
			}
		}

		&.current {
			border: 1px solid $blue;

			.icx {
				color: $blue;
			}
		}
		.progress-text {
			position: absolute;
			left: -1rem;
			width: 90px;
			font-size: 12px;
			top: 2.2rem;
		}
		.fa-plane {
			[dir="rtl"] & {
				transform: rotateZ(180deg);
			}
		}
	}
}

.services {
	.item {
		height: fit-content;
		border: 1px solid #e5e2e4;
		margin-inline-end: 15px;
		border-radius: 10px !important;
		display: flex;
		flex-basis: 32%;
		justify-content: center;
		padding: 1rem;
		flex-direction: column;
		align-items: center;
		&:last-child {
			margin-inline-end: 0;
		}
		.select {
			border: 1px solid #aeaeae;
			padding: 0.25em 4em;
			border-radius: 5px;
			cursor: pointer;
			&:hover {
				color: #fff;
				background: #165df8;
				border: 1px solid #165df8;
			}
		}
		&.selected {
			background: #e5effe;
			.select {
				color: #fff;
				background: #165df8;
				border: 1px solid #165df8;
			}
		}
		&.disabled {
			background-color: transparent !important;
			.select {
				color: #aeaeae;
				background: none;
				border: 1px solid #aeaeae;
				cursor: no-drop;
			}
		}
	}
}

.confirmation {
	.btn-link {
		border: none;
		background: none;
		font-size: 0.9em;
		color: #007bff;
	}
}
