.team-management-tabs .nav-tabs {
	display: flex;
	justify-content: center;
}
.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}
.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.nav-link {
	border: none !important;
}
html[dir="rtl"] .is-active {
	border-bottom: 3px solid #007bff !important;
	padding: 3px 35px !important;
	color: #007bff !important;
	border-radius: none !important;
	min-height: auto !important;
}
html[dir="ltr"] .is-active {
	border-bottom: 3px solid #007bff !important;
	padding: 12px 45px !important;
	color: #007bff !important;
	border-radius: none !important;
	min-height: auto !important;
}
.nav-link .btn-nav {
	font-size: 17px !important;
	font-weight: 500 !important;
}
.nav-link .btn-nav:not(.is-active) {
	color: #373334 !important;
}
.team-filter {
	.control-field__body.select {
		padding: 0.625rem 0.625rem 0.3125rem 0.625rem !important;
	}
}
.user-model {
	//background: red !important;

	top: 70px !important;
	right: 30% !important;

	position: absolute !important;
	z-index: 41 !important;
	//padding: 30px !important	;

	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important;
	.user-model-content {
		width: 720px !important;
		//height: 400px !important;
	}
}
.bulk-edit .modal-content {
	min-height: auto !important;
	border-radius: 0;
	border: 0;
}
.submit-btn {
	button {
		padding: 10px 10px !important;
		margin: 0 10px !important;
		height: 40px !important;
	}
}
.add-user-btn {
	padding: 10px 10px !important;
	margin: 8px 10px !important;
	height: 40px !important;
}

// .table {
// 	border: none !important;
// 	border-collapse: separate;

// 	border-spacing: 0 5px;
// 	thead {
// 		color: #fff !important;
// 		tr {
// 			border: none !important;

// 			border-radius: 80px !important;
// 			background-color: #324356 !important;
// 			color: #fff !important;
// 			box-shadow: 0 0 1px #7d7d7d;
// 			border-radius: 8px;
// 			overflow: hidden;
// 		}
// 		// html[dir="rtl"] th:first-of-type {
// 		// 	border-radius: 8px 0 0 8px;
// 		// 	background-color: red !important;
// 		// }
// 		// html[dir="rtl"] th:last-of-type {
// 		// 	border-radius: 0 8px 8px 0;
// 		// }
// 	}
// 	tbody {
// 		tr {
// 			border-radius: 80px !important;
// 			background: #fff;
// 			box-shadow: 0 0 1px #7d7d7d;
// 			border-radius: 8px !important;
// 			padding: 5px 8px !important;
// 			overflow: hidden;
// 		}
// 	}

// 	th {
// 		border: none !important;
// 		padding: 8px 4px !important;
// 		vertical-align: middle !important;
// 	}
// 	td {
// 		border: none !important;
// 		//padding: 8px 4px !important;
// 		vertical-align: middle !important;
// 	}
// }

//.group {
.team-model {
	top: 70px !important;
	right: 38% !important;

	position: absolute !important;
	z-index: 41 !important;
	//padding: 30px !important	;

	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important;
	.team-model-content {
		width: 450px !important;
		//height: 400px !important;
		padding: 5px 10px !important;
	}
}
.modal-content {
	min-height: auto !important;
	border-radius: 0;
	border: 0;
}
//}

// html[dir="ltr"] th:first-of-type {
// 	border-radius: 8px 0 0 8px;
// }
// html[dir="ltr"] th:last-of-type {
// 	border-radius: 0 8px 8px 0;
// }
// html[dir="rtl"] th:first-of-type {
// 	border-radius: 0px 8px 8px 0px;
// }
// html[dir="rtl"] th:last-of-type {
// 	border-radius: 8px 0 0 8px;
// }

// html[dir="ltr"] td:first-of-type {
// 	border-radius: 8px 0 0 8px;
// }
// html[dir="ltr"] td:last-of-type {
// 	border-radius: 0 8px 8px 0;
// }

// html[dir="rtl"] td:first-of-type {
// 	border-radius: 0 8px 8px 0;
// }
// html[dir="rtl"] td:last-of-type {
// 	border-radius: 8px 0 0 8px;
// }
.submit-btn.footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	margin: 7px 0 !important;
}

// .control-field__body.select .control-field__label {
// 	top: 2px !important;
// }
.phone-supplier {
	.control-field__body {
		padding: 0.925rem 0.25rem 0.3125rem 0.625rem !important;
		width: 72% !important;
	}
	.control-field__prepend {
		padding: unset !important;
		width: 28% !important;
		display: flex;
		align-items: center;
	}
}
.status-filter-user {
	.control-field__body.select .control-field__label {
		top: 0 !important;
	}
}
.fl-border{
	border: 1px solid #D1D6DD;
	border-bottom:none ;
}
.grop-box{
background-color: white;
border: 1px solid #D1D6DD;
padding: 1rem 0px;
}