@import "../base/variables";

#sideNav {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100%;
	width: 70px;
	top: 0;
	left: 0;
	background-color: #fbfaff;
	text-align: center;
	z-index: 1001;
	border-right: 1px solid #e9e9ef;

	transition: 0.4s;
}

[dir="rtl"] #sideNav {
	right: 0;
	text-align: right;
}

#sideNav.open {
	width: $openNav;
	max-width: $openNavMax;
}

.sidenav_list {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	text-align: left;
}

.sidenav_header {
	margin-top: 2.5rem;
	margin-bottom: 1rem;
	font-weight: bold;
	font-size: 1rem;
	padding: 0 1rem;
	transition: 0.4s;
	transition-delay: 0.2s;
	text-align: start;
}

.sidenav_item {
	position: relative;
	display: block;
}

.sidenav_link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: 50px;
	margin: 0.25rem 0;
	color: $blue-gray;
}

.sidenav_item .dash-text {
	margin-inline-start: 1rem;
	// color: $blue-gray;

	display: block;
	position: relative;
	font-size: 0.9rem;
	font-weight: 500;
}

.sidenav_icon {
	flex: 0 0 22px;
	width: 22px;
}

.sidenav_icon svg,
.sidenav_arrow svg {
	width: 100%;
	fill: $blue-gray;
}

.sidenav_arrow svg path {
	stroke: $blue-gray;
}

.sidenav_arrow {
	position: absolute;
	inset-inline-end: 10px;
	width: 7px !important;
}

[dir="rtl"] .sidenav_arrow {
	transform: scale(-1, 1);
}

.dash-text {
	margin-bottom: 0;
	// display: none;
	// visibility: hidden;
	// opacity: 0;
	// width: 0;
	// transition: .4s;
	// font-weight: 700;
}
.sidenav_arrow {
	transform: rotate(0deg);
	transition: 0.1s;
}
///////////////////////////////////// if the nav open  ///////////////////////////////////////////
.open .sidenav_link {
	justify-content: start;
}

.open .sidenav_link {
	padding: 1rem;
}

.open .dash-text {
	// display: block;
	visibility: visible;
	opacity: 1;
	width: auto;
	transition: 0.4s;
	transition-delay: 0.2s;
}
.open {
	.sidenav_dropdown {
		padding: 0 calc(2rem + 22px);
		.dropdown_item {
			position: relative;
			padding: 5px 0;
			.dropdown_link {
				color: $blue-gray;
				transition: 0.2s;
				&:hover {
					color: $blue-500;
					transition: 0.2s;
				}
			}
			.active {
				color: $blue-500;
			}
		}
	}
	.toggled {
		transform: rotate(90deg);
		transition: 0.1s;
	}
}

///////////////////////////////////// if the nav closed  ///////////////////////////////////////////
.closed {
	.sidenav_item {
		&:hover {
			width: $openNav;
			transition: none;
			background-color: #ebe6ff;
			// box-shadow: 0px 2px 12px #0000003e;

			.dash-text {
				visibility: visible;
				opacity: 1;
				padding: 0 1rem;
				width: 100%;
				transition: none;
				box-shadow: none;
				display: block;
			}

			.sidenav_dropdown {
				position: absolute;
				display: flex;
				justify-content: center;
				flex-direction: column;
				background-color: #fff;
				inset-inline-start: 70px;
				width: calc(100% - 70px);
				box-shadow: 5px 10px 12px #0000003e;

				.dropdown_item {
					position: relative;
					padding: 5px 20px;
					.sidenav_arrow {
						visibility: visible;
						opacity: 1;
						display: block;
						top: 50%;
						transform: translateY(-50%);
					}
					.active {
						color: $blue-500 !important;
						&::after {
							content: "";
							position: absolute;
							height: 70%;
							top: calc(50% + 3px);
							transform: translateY(-50%);
							background-color: $blue-500;
							width: 3px;
							inset-inline-end: 0;
						}
					}
					.dropdown_link {
						width: 100%;
						margin: 0.25rem 0;
						color: $blue-gray;
						display: flex;
						transition: 0.4s;
						&:hover {
							color: $blue-500;
							transition: 0.4s;
						}
					}
					&:hover {
						.sidenav_arrow svg path {
							stroke: $blue-500;
							transition: 0.4s;
						}
					}
				}
			}
		}
	}

	.sidenav_link {
		padding-inline-start: 20px;

		.dash-text {
			display: none;
		}

		&:hover {
			transition: none;
		}

		.sidenav_icon,
		.sidenav_icon svg {
			box-shadow: none;
		}
	}

	.sidenav_dropdown,
	.sidenav_arrow {
		display: none;
		// visibility: hidden;
		// opacity: 0;
		// width: 0;
	}

	.sidenav_header {
		text-align: center;
	}
}

// active
.sidenav_link.active {
	position: relative;
}

.sidenav_link.active::after {
	content: "";
	position: absolute;
	height: 70%;
	top: calc(50% + 3px);
	transform: translateY(-50%);
	background-color: $blue-500;
	width: 3px;
	inset-inline-end: 0;
}
.sidenav_link svg {
	fill: rgba(84, 90, 109, 0.2) !important;
}
.sidenav_link.active,
.sidenav_link.active svg,
.sidenav_link.active .dash-text,
.sidenav_link.active .sub-dash-text {
	color: $blue-500;
}
.sidenav_link.active .sidenav_arrow svg path {
	stroke: $blue-500;
}

.sidenav_dropdown .dropdown_item .dropdown_link {
	font-weight: 500;
	display: flex;
}
.sub-dash-text {
	margin-inline-start: 8px;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	font-size: 0.87rem;
}

.dropdown_link svg {
	height: 18px;
	width: 18px;
	fill: rgba(84, 90, 109, 0.2) !important;
}

.sidenav_link.active .dropdown_item svg,
.sidenav_link.active .sub-dash-text {
	color: $blue-500;
}
