@font-face {
	font-family: "#{$icomoon-font-family}";
	src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g1f08b")
			format("truetype"),
		url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g1f08b")
			format("woff"),
		url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g1f08b##{$icomoon-font-family}")
			format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.icx {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "#{$icomoon-font-family}" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icx-arrow-right {
	&:before {
		content: var(--icx-arrow-right);
	}
}
.icx-bell {
	&:before {
		content: $icx-bell;
	}
}
.icx-financail-payment {
	&:before {
		content: $icx-financail-payment;
	}
}
.icx-comment {
	&:before {
		content: $icx-comment;
	}
}
.icx-camera-location {
	&:before {
		content: $icx-camera-location;
	}
}
.icx-edit {
	&:before {
		content: $icx-edit;
	}
}
.icx-hotel {
	&:before {
		content: $icx-hotel;
	}
}
.icx-map {
	&:before {
		content: $icx-map;
	}
}
.icx-itinerary {
	&:before {
		content: $icx-itinerary;
	}
}
.icx-moon {
	&:before {
		content: $icx-moon;
	}
}
.icx-plane {
	&:before {
		content: $icx-plane;
	}
}
.icx-share {
	&:before {
		content: $icx-share;
	}
}
.icx-star {
	&:before {
		content: $icx-star;
	}
}
.icx-transport {
	&:before {
		content: $icx-transport;
	}
}
.icx-trash {
	&:before {
		content: $icx-trash;
	}
}
.icx-eye-slash {
	&:before {
		content: $icx-eye-slash;
	}
}
.icx-building {
	&:before {
		content: $icx-building;
	}
}
.icx-eye {
	&:before {
		content: $icx-eye;
	}
}
.icx-images {
	&:before {
		content: $icx-images;
	}
}
.icx-left-solid-arrow {
	&:before {
		content: var(--icx-left-solid-arrow);
	}
}
.icx-right-solid-arrow {
	&:before {
		content: var(--icx-right-solid-arrow);
	}
}
.icx-shield {
	&:before {
		content: $icx-shield;
	}
}
.icx-user {
	&:before {
		content: $icx-user;
	}
}

.icx-desktop {
	&:before {
		content: $icx-desktop;
	}
}
