html[dir="rtl"] .col-containt {
	direction: ltr !important;
}
html[dir="ltr"] .col-containt {
	direction: rtl !important;
}
.col-containt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	margin: 12px 0 !important;
	background: #fcfcfc !important;
	color: #fff !important;
	vertical-align: bottom !important;
	padding: 4px 0 !important;
	border: 1px solid #e6e6e6;
	//direction: rtl !important;
	div {
		button {
			height: 100% !important;
			margin: 0 0 0 5px;
			border-radius: 0 !important;
			padding-top: 9px;
			padding-bottom: 9px;
		}
		.num {
			//display: inline-block;
			height: 40px !important;
			width: 40px !important;
			border-radius: 50%;
			background: #fff;
			color: #324356;
			text-align: center;
			font-weight: 700;
			margin: 0 8px;
			//vertical-align: middle !important;

			/**to big size background lenght **/
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.last {
		margin-right: 15px;
		margin-left: 0px;
		font-size: 16px !important;
		cursor: pointer;

		i {
			margin: 0px 8px !important;
			font-size: 18px !important;
		}
	}
}
.num-content {
	color: #324356 !important;
	vertical-align: middle !important;
	/**to big size background lenght **/
	display: flex;
	justify-content: center;
	align-items: center;

	.num {
		display: inline-block;
		height: 25px !important;
		width: 25px !important;
		border-radius: 50%;
		color: #fff !important;
		text-align: center;
		font-weight: 500;
		margin: 0 10px;
		//background-color: grey !important;
		//vertical-align: bottom !important;
	}
}
.card {
	border: none !important;
	background-color: transparent !important;
}
.card-body {
	padding: 5px 0 !important;
	margin: 0 !important;
	border: none !important;
	// background: #fff;
	// .table {
	// 	padding: 0 !important;
	// 	margin: 0 !important;
	// 	tr {
	// 		td {
	// 			.product-build__product-no-data {
	// 				padding: 0 !important;
	// 				margin: 0 !important;
	// 				border-radius: none !important;
	// 			}
	// 		}
	// 	}
	// }
}
// .table {
// 	border: none !important;
// }

.CollapsePlusIcon {
	margin-left: 0px !important;
	margin-right: 10px !important;
	margin-bottom: 0px !important;
	//	color: grey !important;
	//font-size: 22px !important;
}

html[dir="rtl"] .CollapsePlusIcon {
	margin-left: 10px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
}

.editIcon {
	margin-left: 0px !important;
	margin-right: 15px !important;
}

html[dir="rtl"] .editIcon {
	margin-left: 15px !important;
	margin-right: 0px !important;
}

.grey {
	color: grey !important;
}

.blue {
	color: #0184fe !important;
}
.bg-blue {
	background-color: #0184fe !important;
}
.bg-grey {
	background-color: grey !important;
}
.submit-btn.footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	margin: 10px 0 !important;
}
.user-model-content {
	padding: 5px 11px !important;
}
.team-model-content {
	.submit-btn.footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		padding: 0.75rem;
		border-top: 1px solid #dee2e6;
		margin: 7px 0 !important;
	}
}
