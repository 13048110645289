.stepper {
	border-left: 4px solid #39495b;
	height: 68vh;
	padding: 0 0.5rem;
	[dir="rtl"] & {
		border-left: initial;
		border-right: 4px solid #39495b;
	}
	.step {
		background: #e5e5e5;
		border-radius: 50px;
		display: flex;
		margin-bottom: 4rem;
		position: relative;
		z-index: 1;
		.icon {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: #ffff;
			border: 1px solid #acacac;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			&:after {
				content: "";
				outline-width: 3px;
				outline-color: #e5e5e5;
				outline-style: solid;
				height: 108px;
				position: absolute;
				top: 2px;
				z-index: -1;
			}
		}
		&:last-child {
			.icon {
				&::after {
					height: 0;
				}
			}
		}
		.text {
			display: flex;
			align-items: center;
			padding: 0 0.5rem;
			color: #acacac;
			text-decoration: underline;
			font-size: 14px;
		}
	} //step
	.fa-ellipsis-h {
		color: #acacac;
	}
	.active-step {
		background: #1877fa;
		.icon {
			border-color: #1877fa;
			&:after {
				outline-color: #e5e5e5;
			}
		}
		.text {
			color: #fff;
		}
		.fa-ellipsis-h {
			color: #1877fa;
		}
	}
	.success-step {
		background: #1877fa;
		.icon {
			border-color: #1877fa;
			&:after {
				outline-color: #1877fa;
			}
			i {
				color: #1877fa;
			}
		}
		.text {
			color: #fff;
		}
	}
}
