.login-banner {
	// background: url("../../../assets/images/login_image.jpg");
	background-repeat: no-repeat;
	height: 100vh;
	background-color: $dark-blue;
	background-size: cover;

	&__logo {
		width: 50%;
	}
}
