.list-rate {
	background-color: #fff;
	min-height: 60vh;
	overflow: hidden;
	margin-top: 2%;
	border-radius: 8px;
	box-shadow: 0 1px 2px grey;
	.p-15 {
		padding-right: 15px;
		padding-left: 15px;
	}
	.p-0 {
		padding: 0;
	}
	.title {
		font-weight: 500;
		font-size: 18px;
		padding: 10px 0 0;
	}
	.sub-title {
		font-size: 13px;
		font-weight: 300;
	}
	// table {
	// 	border-collapse: inherit;
	// 	border-spacing: 0 0.2em;
	// }
	// table th {
	// 	border: none;
	// 	padding: 6px 8px !important;
	// }
	// table td {
	// 	padding: 6px 8px !important;
	// 	border-top: 1px solid #dee2e6;
	// 	border-bottom: 1px solid #dee2e6;
	// }
	// table th:first-child {
	// 	border-radius: 4px 0 0 4px;
	// }

	// table th:last-child {
	// 	border-radius: 0 4px 4px 0;
	// }
	// table td:first-child {
	// 	border-left: 1px solid #dee2e6;
	// 	border-radius: 4px 0px 0px 4px;
	// }

	// table td:last-child {
	// 	border-right: 1px solid #dee2e6;
	// 	border-radius: 0 4px 4px 0;
	// }
	.btn-add {
		text-transform: capitalize;
		margin-top: 15px;
	}
	.btn-link-edit {
		color: #000000;
		font-size: 14px;
		margin: 0 4px;
	}
	.btn-link-trash {
		color: red;
		font-size: 14px;
		margin: 0 4px;
	}
}
.rate-plan-header {
	display: block;
	width: 100%;
	background-color: #eae8e9;
	color: #324356;
	padding: 8px;
	position: relative;
	font-weight: 500;
	font-size: 14px;
	.pos-arrow {
		position: absolute;
		right: 8px;
		top: 10px;
		[dir="rtl"] & {
			right: unset;
			left: 10px;
		}
	}
}

.box-description {
	background-color: #ebebeb;
	width: 100%;
	border: 1px solid #e5e5e5;
	padding: 20px;
	.title {
		font-size: 14px;
		font-weight: 600;
		color: #354f5c;
	}
	.sub-title {
		font-size: 12px;
		font-weight: 200;
		color: #354f5c;
	}
}
.form-check-label {
	font-size: 14px;
	text-transform: capitalize;
}
.link-delete {
	margin: 20px 5px 0 5px;
	display: inline-block;
}
.item-room {
	background-color: #f8f8f8;
	padding: 20px;
}
.w-120 {
	width: 120px;
}
.input-price {
	width: 100%;
	height: 40px;
	border: none;
	padding: 0 6px;
}
.new-table {
	border-collapse: inherit;
	border-spacing: 4px 8px;
	font-size: 12px;
	.label {
		background-color: #eae8e9;
		color: #686d71;
		padding: 0 4px !important;
		font-size: 12px;
	}
}
.new-table th {
	vertical-align: top;
	border-top: 1px solid #dee2e6;
	background: #686d71;
	color: #fff;
	font-size: 12px;
	text-align: center;
	height: 35px;
	padding: 0.6rem !important;
}
.new-table td {
	border-top: none;
	padding: 0 0px !important;
	vertical-align: middle;
}
.danger-massage {
	font-size: 12px;
	margin-top: 15px;
	text-transform: capitalize;
}
.danger-checkbox {
	border: 1px solid #ff5f59;
}

.percentage {
	display: flex;
	align-items: center;
	padding: 0;
	p {
		font-size: 0.75rem;
		font-weight: 500;
		color: #324356;
	}
}
