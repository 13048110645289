.bg-subNav {
	background-color: #e5e5e5;
	padding: 10px;
	color: #a7afb7;
}

.active-companies-tourism {
	border-top: 2px solid #57c960;
	background-color: #e5e5e5;
	padding: 10px;
	color: #354f5c;
	position: relative;
	&:before {
		content: "";
		transform: rotate(62deg);
		position: absolute;
		bottom: -2px;
		position: absolute;
		left: 44%;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 8px solid #ffffff;
	}
}

.shape-border {
	border: 2px solid #91969b;
	width: 15%;
}

.companies-btn {
	background-color: #354f5c !important;
	color: white !important;
	padding: 10px !important;
	width: 15% !important;
	border-radius: 0 !important;
}

.collapse-border {
	border: 1px solid #a7afb7 !important;
	color: #354f5c;
}

.collapse-table-border {
	border: 1px solid #a7afb7 !important;
	border-top: 0 !important;
}

.circly {
	background-color: #354f5c;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 3px;
	color: white;
}

.file-btn {
	background-color: #324356;
	position: relative;
	color: white;
	font-weight: 500;
	border: none;

	width: 25%;
	padding: 0.25rem;
	font-size: 14px;
	cursor: pointer;
}
.custom-file {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}
.bg-information {
	background-color: #c8c8c8;
}

.bg-wallet-name {
	background-color: #324356;
	transition: 0.5s;
	color: white;
}

.trash:hover {
	color: rgb(231, 28, 28);
	transition: 0.3s;
	cursor: pointer;
}
.trash {
	font-size: 20px;
	transition: 0.3s;
	color: #a7afb7;
}

.text-gray {
	color: #a7afb7 !important;
}
