.visa-nav {
	.tab-content {
		// min-height: 84vh;
		border: 1px solid #dfdfdf;
		border-top: 0 !important;
	}
	.nav {
		display: flex;
		flex-wrap: nowrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		.nav-item {
			min-height: 55px;
			width: 23%;
			text-align: center;
			margin-bottom: 0px !important;
			background: #ebebeb !important;
		}
		.nav-link.active {
			height: 55px !important;
		}
		.nav-link {
			height: 55px !important;
			border: 0.3px solid #dfdfdf;
			border-bottom: 0;
			border-radius: 0 !important;
		}
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-color: #fff !important;
		min-height: 55px !important;
		background-color: #fff !important;
		border-radius: 0 !important;
		border: 1px solid #dfdfdf !important;
		border-bottom: 0 !important;
	}
	.nav-tabs .nav-link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 55px !important;
		background-color: #ebebeb !important;
		border-radius: 0 !important;
		//border: 1px solid #dfdfdf !important;
		border-bottom: 0 !important;

		color: #b0b0b0 !important;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active .product-build-list-item span {
		color: #1877f9d4  !important;
		font-size: 16px;
		font-weight: 600;
	}

	.tab-icons {
		background-color: #ebebeb !important;
	}
	.line-height-1 {
		line-height: 1 !important;
	}
	.no-hotel {
		width: 100%;
		background: #f7f7f7;
		height: 350px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.3125rem;
		p {
			color: #979797;
			margin: 15px 0;
		}
	}
	.btn-yallow {
		background-color: #cb9a51 !important;
		color: #fff;
		font-size: 16px;
	}
	.select-hr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: #979797;
			font-size: 15px;
		}
		div {
			height: 1px;
			width: 88%;
			background: #dfdfdf;
		}
	}
}
.bg-mrz {
	background-color: #979797;
}
.text-visa-header {
	p {
		color: #0c3b5c;
	}
}
.passanger-profile {
	.isDisabled,
	.isDisabled input[type="text"]:disabled,
	.control-field__body,
	.DateInput_input__disabled {
		background: #ebebeb !important;
	}
}
.spinner-border-visa {
	font-size: 9px;
	width: 18px;
	height: 18px;
	color: #007bff;
}
.visa-checkbox:disabled {
	cursor: not-allowed !important;
}
//my network
.w-35 {
	width: 35% !important;
}
.w-65 {
	width: 65% !important;
}
.text-grey {
	color: #354f5c;
}
.collapse-border.network-wallet {
	padding: 0 !important;
}
.modal-network-center {
	// position: relative;
	// top: 30%;
	// left: 0%;
	position: relative;
	left: 17%;
	top: 40%;
	transform: translate(-50%, -50%) !important;
}

.modal {
	.modal-header {
		button {
			background-color: transparent !important;
			font-size: 20px !important;
			color: #686d71 !important;
		}
	}
}
.btn-gray {
	background-color: #eae8e9 !important;
}
.tab-network-wallet {
	border: 1px solid #a7afb7 !important;
	span {
		padding: 14px 40px !important;
	}
}
.font-weight-bold {
	font-weight: bold;
}
.f-18 {
	font-size: 18px;
}
