.product-builder-list {
	background-color: $gray-100;
	padding: 0.3rem;
}
.product-header-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0;
	button {
		margin: 0 0.5rem;
		padding: 0.375rem 2rem;
		font-size: $text-caption;
	}
} //product-header-list

.custom-table {
	font-size: $text-caption;
	border-collapse: separate;
	border-spacing: 0 6px;
	margin-bottom: 0.3rem;
	td,
	th {
		padding: 0.5rem;
		vertical-align: middle;
		border-top: none;
	}
	td {
		&:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			[dir="rtl"] & {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
		}
	}
	th {
		&:first-child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			[dir="rtl"] & {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
		&:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			[dir="rtl"] & {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
		}
	}
	thead {
		background-color: $dark-blue;
		color: $white;
		th {
			border-bottom: 2px solid $dark-blue;
		}
	}
	tbody {
		tr:nth-child(odd) {
			background: $white;
			box-shadow: 0px 0px 1px #7d7d7d;
			border-radius: 8px;
			overflow: hidden;
		}

		tr:nth-child(even) {
			background: #e8e8e8;
			box-shadow: 0px 0px 1px #7d7d7d;
			border-radius: 8px;
			overflow: hidden;
		}
		i,
		.icx {
			font-size: $text-headline;
			margin: 0 0.5rem;
		}
		.products {
			i {
				color: $dark-gray;
				margin: 0 0.3rem;
			}
		}
		.actions {
			i,
			.icx {
				cursor: pointer;
			}
		}
	}
	.btn-success,
	.btn-danger,
	.btn-warning {
		padding: 0.2rem 1rem;
		border-radius: 4px;
		font-size: 12px;
		color: #fff;
		min-width: 80px;
		display: inline-block;
		text-align: center;
	}
	.btn-warning {
		background-color: #f28f08;
		border-color: #f28f08;
	}
} //custom-table
.table-empty {
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #eae8e9;
}
