.home {
	h2 {
		font-weight: 600;
		color: #324356;
		font-size: 1.1em;
	}

	.add {
		font-weight: 500;
		height: 40px;
		width: 125px;
		font-size: 1em;
	}
}

.route-block {
	a {
		padding: 0.9rem 1rem;
		display: inline-block;
		color: rgb(0, 0, 0);
		// transition: all 0.3s ease-in-out;
	}
}
// .table {
// 	th,
// 	td {
// 		padding: 0.3rem !important;
// 		font-size: 14px;
// 	}
// }

.progress-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	.progress {
		width: 70%;
		height: 0.3rem;
		font-size: 0.75rem;
		margin-inline-end: 0.5rem;
	}
	span {
		width: 30%;
	}
	.bg-danger {
		background-color: #ff5f59 !important;
	}
}
.span-rej {
	color: red;
	font-size: 12px;
	margin: 0 5px;
}
.tb-box{
	background-color: white;
	border: 1px solid rgb(220, 218, 218);
	border-radius: 5px;
 }
