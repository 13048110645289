.hotel-box {
	background: #e5e5e5;
	padding: 1rem;
	.head {
		display: flex;
		align-items: center;
		.title {
			font-weight: 700;
			color: #324356;
		}
		.bookable {
			background: #1877fa;
			color: #fff;
			padding: 0.1rem 0.5rem;
			border-radius: 3px;
			font-size: 12px;
			overflow: hidden;
			margin: 0 0.5rem;
		}
	}
}

.dashboard-section {
	position: fixed;
	top: 69px;
	width: 100%;
	height: 100vh;
	background: #f4f4f4;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
	overflow: hidden;
	transition: opacity 0.35s ease-in-out;

	.dashboard-box {
		// margin: 0rem 8rem;
		padding: 5rem 0;
		margin: auto;
		width: 100%;
	}
	.title-box {
		display: flex;
		justify-content: space-between;
		button {
			font-size: 12px;
			padding: 0.5rem 2rem;
		}
	} //title-box
	.dashboard-ul {
		display: flex;
		// justify-content: space-around;
		justify-content: space-between;
		flex-wrap: wrap;
		// margin: 3rem 0;
		.active {
			.img-box {
				background: #1777fb;
			}
			.dash-text {
				color: #1777fb;
			}
		}
		a {
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			display: inline-block;
			width: 20%;
			margin-top: 2rem;

			// &:hover .img-box{
			//     background: #1777FB;
			// }
			// &:hover .dash-text{
			//     color: #1777FB;
			// }
		}
		li {
			text-align: center;
		}
		.img-box {
			position: relative;
			background: #dde7f5;
			width: 100px;
			height: 100px;
			line-height: 100px;
			text-align: center;
			border-radius: 50%;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			margin: auto;
			.bell-icon {
				position: absolute;
				top: 2px;
				right: 2px;
				background: #fe6c72;
				display: inline-flex;
				width: 25px;
				height: 25px;
				line-height: 25px;
				border: 2px solid #fff;
				border-radius: 50%;
				color: #fff;
				padding: 0.3rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			img {
				width: 45px;
				height: 45px;
				object-fit: contain;
			}
		}
		.dash-text {
			margin-top: 0.8rem;
			color: #222;
			font-weight: bold;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
		}
	} //dashboard-ul
}

@keyframes fadeout {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.9);
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.toggleOut {
	animation: fadeout 0ms;
	transform: scale(0) rotateX(0);
}

.toggleIn {
	animation: fadein 500ms;
	opacity: 1;
}

.menu-img {
	width: 20px;
}
