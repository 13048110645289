@import "../../base/variables";

.bar-header {
	background-color: $gray-200;
}
.bar-title {
	color: $dark-blue;
}

.step-dashes {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	border: 1px solid $gray-200;
	z-index: 1;
	background-color: $gray-200;
}

.pointer {
	cursor: pointer;
	text-decoration: none !important;
}
.progressbarItem {
	display: flex;
	flex-direction: column;
	width: 20%;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.progressbarItem:last-child {
	width: 20%;
}
.progressbar {
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon-step {
	margin: 8px;
	color: #c1c3c5;
}
.active-status {
	border: 2px solid #1877fa;
	background-color: white;
}
.progressbarItem-name {
	font-size: 10px;
}

.extra-text {
	position: absolute;
	left: 5px;
	right: 5px;
}

.active-item:after {
	content: "- - - - - - - - - - - - - - - - -  - - - - ";
	font-weight: 800;
	color: #00b437 !important;
	top: 6px;
}

.step-holder {
	display: flex;
	justify-content: center;
	align-items: center;
}

.item-name {
	font-size: 14px;
}
html[dir="rtl"] .step-holder:after {
	left: -39%;
}
html[dir="ltr"] .step-holder:after {
	right: -50%;
}
.step-holder:after {
	content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - -";
	font-weight: 800;
	color: $gray-200;
	top: 6px;

	position: absolute;
}

.remove-after:after {
	content: "";
}
.adjust-step-holder {
	justify-content: flex-start !important;
}

.second-title {
	color: #c8c7c7;
}
.bg-card {
	background-color: #686d71;
	color: white;
	font-size: 14px;
}
.bg-card-second {
	background-color: $gray-300;
	color: white;
}
.text-card-second {
	color: $gray-300;
}
.bg-requests {
	background-color: #f4f4f4;
}
.bg-card-info {
	background-color: #f4f4f4;
	color: #324356;
}
.bg-card-error {
	background-color: #ffdedd;
	color: #ff5f59;
}
.bg-card-btn {
	background-color: transparent;
	color: white;
	border: none;
	font-size: 13px;
}

.room-p {
	padding: 1.1rem;
}
.room-icon-p {
	padding: 1.1rem;
}
.hilight {
	border-bottom: 1px solid #1877fa;
	font-size: 11px;
	color: #1677f6;
}
.is-active-reservations {
	// color: white !important;
	background-color: #1877fa !important;
	font-weight: 500;
	position: relative;
	&:before {
		content: "";
		transform: rotate(62deg);
		position: absolute;
		bottom: -2px;
		position: absolute;
		left: 48%;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 8px solid #ffffff;
	}
}
.active-icon {
	color: white;
}

.radio_bnt {
	width: 20px;
	height: 20px;
}

.Checkbox input[type="radio"] {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border: 2px solid #656565;
	border-radius: 50%;
	outline: none;
}
.Checkbox input[type="radio"]:before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin: auto;
	margin-top: 1px;
}

.Checkbox input[type="radio"]:checked:before {
	background: #1677f6;
}
.Checkbox input[type="radio"]:checked {
	border: 3px solid #1677f6;
}

.check-box-label {
	font-size: 14px;
	font-weight: 500;
}
.upload-file {
	border: 2px dashed #c1c3c5;
	padding: 2rem;
	width: 100%;
	text-align: center;
}
.file-btn {
	background-color: #324356;
	position: relative;
	color: white;
	font-weight: 500;
	border: none;
	border-radius: 0.15rem;
	width: 25%;
	padding: 0.25rem;
	font-size: 14px;
	cursor: pointer;
}
.custom-file {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}
.file {
	background-color: #c8c7c7;
	border-radius: 0.25rem;
	padding: 0.5rem;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.stop-share {
	font-size: 13px;
	color: black;
	text-decoration: underline !important;
}
