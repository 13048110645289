/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.backOffice-wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
	color: $blueBlack;

	// .btn:hover {
	// 	background: #17a2b8;
	// }

	.sidebar {
		min-width: 250px;
		max-width: 250px;
		background: $blueBlack;
		color: #adb5bd;
		margin-left: -250px;
		transition: all 0.5s;
		[dir="rtl"] & {
			margin-left: 0;
			margin-right: -250px;
		}
		min-height: 100vh;
	}

	.sidebar.is-open {
		margin-left: 0;
		transition: 0.5s;
		[dir="rtl"] & {
			margin-left: initial;
			margin-right: 0;
		}
	}

	.sidebar-header {
		background: $blueBlack;
		color: #adb5bd;
	}

	.sidebar-header h3 {
		color: $white;
		padding: 1em;
	}

	.sidebar ul p {
		color: $white;
		padding: 10px;
	}

	// .collapse.show,
	// .collapsing {
	// 	background: #313b4c;
	// }

	.main-menu {
		display: flex !important;
		justify-content: space-between;
		align-items: baseline;
		padding: 0 1rem !important;
		color: #adb5bd !important;
		i {
			[dir="rtl"] & {
				transform: rotate(180deg);
			}
		}
		&:hover {
			background: #313b4c;
			transition: 0.2s;
			cursor: pointer;
			.nav-item,
			i {
				color: $white;
			}
		}
	}
	.nav-item {
		transition: all 0.3s ease-in-out;
		&:hover {
			color: #adb5bd !important;
		}
	}
	.activeClass {
		background: #313b4c;
		color: $white;
	}
	// .subActiveClass {
	//   color: #fff !important;
	//   font-weight: bold;
	// }

	.office-navbar {
		.nav-item {
			font-weight: 300;
			display: block;
			padding: 0.5rem 1rem;

			a {
				color: #adb5bd !important;
				// padding: 0.5rem 0;
				// &:hover {
				//   color: #fff !important;
				// }
			}
		}

		.nav-item.open {
			color: #e9ecef;
			background: #313b4c;
		}

		.nav-item.active {
			color: #e9ecef;
			background: #2d3646 !important;
		}

		// .nav-item:hover {
		// 	background: #313b4c;
		// 	color: $white;
		// 	transition: 0.2s;
		// }

		.nav-item .svg-inline--fa {
			color: inherit;
		}
	}

	li a.dropdown-toggle::after {
		display: inline-flex;
		position: relative;
		left: 60%;
		top: 10%;
	}

	.sidebar-header > button {
		position: relative;
		float: right;
		margin: 0.5em;
		font-size: 2rem;
		cursor: pointer;
		display: none;
	}

	/* ---------------------------------------------------
			CONTENT STYLE
	----------------------------------------------------- */
	.content {
		padding: 10px;
		margin-left: 0;
	}

	@media only screen and (max-width: 575.98px) {
		body {
			overflow: hidden;
		}

		.content.is-open {
			display: none;
			transition: all 0.5s;
		}

		.sidebar.is-open {
			min-width: 100%;
			max-width: 100%;
			margin-left: 0;
			transition: all 0.5s, height 0s;
			[dir="rtl"] & {
				margin-left: initial;
				margin-right: 0;
			}
		}

		.sidebar.is-open > .sidebar-header button {
			display: block;
		}

		li a.dropdown-toggle::after {
			display: inline-block;
			position: relative;
			left: 68%;
		}
	}

	//Tables
	.back-office-table {
		td,
		th {
			padding: 0.75rem;
			vertical-align: middle;
			border-top: none;
			font-weight: 700;
			color: #354f5c;
		}
		td {
			&:last-child {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				[dir="rtl"] & {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
				}
			}
		}
		th {
			&:first-child {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				[dir="rtl"] & {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
			&:last-child {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				[dir="rtl"] & {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
				}
			}
		}
		thead {
			background-color: $white;
			color: $dark-blue;

			th {
				border-bottom: 2px solid $white;
				//color: #a7afb7;
			}
		}
		tbody {
			tr:nth-child(even) {
				background: #ffffff;
				box-shadow: none !important;
				border-radius: 0;
				border: none;
			}

			tr:nth-child(odd) {
				box-shadow: none !important;
				border-radius: 0;
				border: none;
				background-color: #edf2f7 !important;
			}
		}
		i {
			cursor: pointer;
		}
	}

	.invitation-head {
		border-bottom: 1px solid #eae8e9;
		padding-bottom: 0.4rem;
	}

	.icon-round {
		border: 1px solid #00b437;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main-tabs .main-nav .nav-item .nav-link {
		background-color: #a7afb7;
		color: #fff;
		min-height: 55px;
	}
	.main-tabs .main-nav .nav-link.active {
		min-height: 55px;
	}
	.main-tabs .main-nav .nav-link.active::after {
		right: 45%;
	}
	.main-tabs .main-nav .nav-item {
		margin: 0;
	}
	.nav-tabs .nav-link.active {
		background-color: $blueBlack !important;
	}
	.nav-tabs .nav-link {
		border-radius: 0;
	}
	.main-filter {
		padding: 1rem 0;
	}

	.border-r-0 {
		border-right: 0;
		outline: none;
		box-shadow: none;
		&:focus {
			border-color: #ced4da;
		}
		[dir="rtl"] & {
			border-right: 1px solid #ced4da;
			border-left: 0;
			border-radius: 0;
		}
	}

	.input-group-text {
		[dir="rtl"] & {
			border-right: 0;
			border-radius: 0;
		}
	}

	.control-field {
		border-radius: 0;
	}
} //backOffice-wrapper

//team-management
.team-management__back-office {
	.product-build__product-collpase {
		.button-collapse {
			.title-style-container {
				.right-items-container {
					width: 96%;
					background: $blueBlack;
					height: 100%;
					.product-content-items {
						color: #fff;
						font-size: 16px;
						.img-circle-wrapper {
							background-color: #fff;
							span {
								color: $blueBlack;
							}
						}
					}
				}
				.icon-items-section {
					width: 4%;
					justify-content: center;
					background: #9d9d9d;
					height: 100%;
					cursor: pointer;
				}
			}
		}
	}
}

//Contract-Management
.property {
	.nav-tabs {
		border-bottom: 0;
	}
	h1 {
		font-weight: 600;
		color: #324356;
		font-size: 1.1em;
	}
	// .table {
	// 	th {
	// 		padding: 0.3rem !important;
	// 		font-size: 14px;
	// 	}
	// }
	.is-active {
		color: #324356;
		font-weight: 500;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			bottom: -8px;
			position: absolute;
			left: 48%;
			width: 0px;
			height: 0px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 8px solid #b4b4b4;
		}
	}

	.content-box {
		background: #fff;
		border: 1px solid #d9dde0;
		border-radius: 4px;
		padding: 1em;
	}

	.header-box {
		background: #eae8e9;
		border-radius: 4px;
		padding: 0.5em 1em;
		font-weight: 500;
	}

	.name-check {
		& > * {
			display: inline-block;
			margin-inline-end: 2em;
		}
	}

	.room {
		table td,
		table {
			border-collapse: separate;
			tr:first-child th:first-child {
				border-top-left-radius: 10px;
			}
			tr:first-child th:last-child {
				border-top-right-radius: 10px;
			}
			tr:last-child th:first-child {
				border-bottom-left-radius: 10px;
			}
			tr:last-child th:last-child {
				border-bottom-right-radius: 10px;
			}
			th {
				min-width: 185px;
				border-bottom: none;
			}
			tr {
				.my-2 {
					margin: 0 !important;
				}
			}
		}
		.custom-name {
			color: #1877fa;
			background: #fff;
			border: none;
			padding: 0.625rem 0.625rem 0.3125rem 0.625rem;
			border: 1px solid #eae8e9;
			border-radius: 0.3125rem;
			i {
				padding: 0 0.2rem;
			}
		}
		.delete-name {
			position: absolute;
			right: 10px;
			top: 12px;
			cursor: pointer;
		}
		// .table th,
		// .table td {
		//   border-top: none !important;
		//   padding-bottom: 0.1rem !important;
		// }
	}

	.most {
		.amenity {
			padding: 0.4rem;
			padding-left: 0.8rem;

			&.checked {
				background: white;
				padding: 14px;
				// border: 1px dashed #e5e5e5;
			}

			.amenity-check {
				margin-inline-end: 10rem;
			}
		}
	}
	.amenity {
		background:#eeeeee73;
		padding: 0.8rem;
		&.checked {
			border: 1px solid #eee !important;
			background: white;
			padding: 14px;
			border: none;
		}

		.amenity-check {
			margin-inline-end: 10rem;
		}
	}
}

.property-details,
.platform-details,
.personal-data {
	.head-manager {
		margin-top: 1rem;
		p {
			background: #c8c8c8;
			padding: 0.5rem;
			color: #354f5c;
			font-weight: 700;
		}
	}
}

.contract-upload {
	background-color: #f5f5f5;
}

.isExpanded {
	.fa-angle-double-up:before {
		content: "\f103" !important;
	}
}
.fa-rotate-90 {
	[dir="rtl"] & {
		transform: rotate(-90deg);
	}
}
.base-commission-holder {
	.form-control {
		padding: 5px;
		font-size: 14px !important;
	}
}

.Button-Pointer {
	cursor: pointer !important;
}

.DatePicker .MuiFilledInput-root {
	background-color: #fff !important;
	border: 1px solid #eae8e9 !important;
	border-radius: 0.3125rem !important;
}

.DatePicker .MuiFilledInput-underline:before {
	border-bottom: unset !important;
}

.DatePicker .MuiFilledInput-underline .MuiInputBase-input {
	height: 0.1em;
	padding: 17px 12px 17px;
}

.formControl {
	padding: 0.75rem 0rem !important;
}

.DatePicker-Form .MuiFilledInput-root {
	background-color: #fff !important;
	border: 1px solid #eae8e9 !important;
	border-radius: 0 !important;
}

.DatePicker-Form .MuiFilledInput-underline:before {
	border-bottom: unset !important;
}

.DatePicker-Form .MuiFilledInput-underline .MuiInputBase-input {
	//height: 0.1em;
}

.Verify-Button {
	background-color: #00ad0d;
	border-color: #00ad0d;
	margin-left: 5px;
}

.Verify-Button:hover,
.Verify-Button:visited,
.Verify-Button:active {
	background-color: #00ad0d !important;
	border-color: #00ad0d !important;
}

.Verify-Button:focus {
	background-color: #00ad0d !important;
	border-color: #00ad0d !important;
	box-shadow: 0 0 0 0.2rem #00ad0d !important;
}

.Reject-Button {
	background-color: #ff5050 !important;
	border-color: #ff5050 !important;
}

.Reject-Button:hover,
.Reject-Button:visited,
.Reject-Button:active {
	background-color: #ff5050 !important;
	border-color: #ff5050 !important;
}

.Reject-Button:focus {
	background-color: #ff5050 !important;
	border-color: #ff5050 !important;
	box-shadow: 0 0 0 0.2rem #ff5050 !important;
}

.Action-Button {
	display: flex;
	flex-direction: row;
}
