.loader {
	background-color: $gray-150;
	color: $dark-blue;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100vh;
	z-index: 99999;
}

.inner-loader {
	background-color: #00000047;
	color: $dark-blue;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		max-width: 120px;
	}
}
