.view-property-active {
	color: #324356 !important;
	font-weight: 500;
	position: relative;
	border-bottom: 3px solid #007bff !important;
	padding: 9px 35px !important;
	color: #007bff !important;
	border-radius: 7px;

	min-height: auto !important;
	&:before {
		content: "";
		position: absolute;
		bottom: -8px;
		position: absolute;
		left: 48%;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 8px solid #b4b4b4;
	}
}
.bg-viewProperty-nav {
	padding: 0.9rem 3rem;
	display: inline-block;
	color: #b4b4b4;
	background: #fdfdfd;
	border: 1px solid #d9dde0;
	border-radius: 4px;
	margin-inline-end: 10px;
}

.content-box {
	background: #fff;
	border: 1px solid #d9dde0;
	border-radius: 4px;
	padding: 1em;
}

.header-box {
	background: #eae8e9;
	border-radius: 4px;
	padding: 0.5em 1em;
	font-weight: 500;
}

.name-check {
	& > * {
		display: inline-block;
		margin-inline-end: 2em;
	}
}

.room {
	table td,
	table {
		border-collapse: separate;
		tr:first-child th:first-child {
			border-top-left-radius: 10px;
		}
		tr:first-child th:last-child {
			border-top-right-radius: 10px;
		}
		tr:last-child th:first-child {
			border-bottom-left-radius: 10px;
		}
		tr:last-child th:last-child {
			border-bottom-right-radius: 10px;
		}
		th {
			min-width: 185px;
			border-bottom: none;
		}
		tr {
			.my-2 {
				margin: 0 !important;
			}
		}
	}
	.custom-name {
		color: #1877fa;
		background: #fff;
		border: none;
		padding: 0.625rem 0.625rem 0.3125rem 0.625rem;
		border: 1px solid #eae8e9;
		border-radius: 0.3125rem;
		i {
			padding: 0 0.2rem;
		}
	}
	.delete-name {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}
	// .table th,
	// .table td {
	// 	border-top: none !important;
	// 	padding-bottom: 0.1rem !important;
	// }
}

.most {
	.amenity {
		padding: 0.4rem;
		padding-left: 0.8rem;

		&.checked {
			background: white;
			padding: 14px;
			// border: 1px dashed #e5e5e5;
		}

		.amenity-check {
			margin-inline-end: 10rem;
		}
	}
}
.amenity {
	padding: 0.8rem;
	&.checked {
		background: white;
		padding: 14px;
		border: none;
	}

	.amenity-check {
		margin-inline-end: 10rem;
	}
}

.gray {
	color: #a7afb7;
}

.gallery {
	margin: 1rem 0 3rem 0;
	.gallery-item {
		// width: 250px;
		border: 1px solid #a7afb7;
		height: 100%;
		img {
			width: 100%;
			height: 280px;
			object-fit: cover;
		}
	}
}

.bg-trash {
	background: #a7afb7;
}

.delete-room {
	width: 20px;
	// padding-top: 1rem;
	i {
		cursor: pointer;
	}
}

.upload-images {
	border: 2px dashed #e5e5e5;
	#uploadFile {
		display: block;
		width: 100%;
		text-transform: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
}

.btn {
	&.cancel {
		background: #e5e2e4;
		color: #000;
	}
	&.cancel:hover {
		background: #9c9c9c;
		color: #fff;
	}
}

.customModal {
	max-width: 80% !important;
	min-height: 95vh !important;
	img {
		min-height: 600px;
	}
}

.img-cover {
	border: 2px solid rgb(151, 150, 153);
	img {
		object-fit: cover;
		width: 100%;
		height: 30vh;
	}
}
