.promotions-type {
	background-color: #fff;
	overflow: hidden;
	margin-top: 2%;
	border-radius: 6px;
	padding: 12px 10px;

	.icon-item {
		img {
			width: 100%;
		}
	}
	.promotions-description {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.title-item {
			font-size: 20px;
			font-weight: 700;
		}
		.subtitle-item {
			font-size: 16px;
			font-weight: 300;
		}
	}
	.promotions-Link {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-start;
		align-self: center;
		a {
			padding: 20px;
			font-size: 23px;
			color: #000000;
		}
	}
}
.box-add-promotion {
	margin-top: 2%;
	.m-auto {
		margin: auto;
	}
	.btn-primary {
		text-transform: capitalize;
	}
	.btn-light {
		text-transform: capitalize;
	}
}
.promotions-days {
	padding: 0 12px !important;
	p {
		font-size: 0.75rem;
		font-weight: 500;
		color: #324356;
		margin-bottom: 0;
		width: 100%;
		text-align: var(--left);
		text-transform: uppercase;
		padding: 7px 0 10px;
	}
	.form-check-label {
		font-size: 16px;
		color: #686d71;
	}
	.form-check-inline {
		margin-right: 2rem;
	}
}
