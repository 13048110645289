// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-light: 100;

html {
	--right: right;
	--left: left;
	--dir: ltr;
	--productListTranslate: 150%;
	--percentage: 100%;
}

// @import "~bootstrap";

// Base Files
@import "base/variables";
@import "base/reset";
@import "base/functions";
@import "base/mixins";
@import "base/colors";
@import "base/typography";
@import "base/icomoon";
@import "base/general";
@import "base/custom-modal";

// Shared Components
@import "shared/all";
@import "modules/backOffice/all";

// Modules
@import "./modules/auth/all";
@import "./modules/HotelLayout/all";
@import "./modules/verify-hotel/all";
@import "./modules/RatePlan/all";
@import "./modules/visa/all";

//Team Management
@import "./modules/TeamManagement/TeamManagement.scss";
@import "./modules/TeamManagement/Collapse.scss";
@import "./app.scss";
@import "./custom.scss";
@import "./bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");

body {
	font-family: "Roboto", sans-serif;
}
.input_icon .control-field__append {
	position: absolute;
	right: 0;
	height: 100%;
	padding: 0;
	top: 0;
	display: flex;
	width: 20%;
	align-content: center;
	justify-content: center;
	align-items: center;
	background: #d1d1d1 !important;
	span {
		background: #d1d1d1 !important;
	}
}
.placeholderText-right {
	position: absolute;
	right: 6px;
	/* width: 70px; */
	bottom: 5px;
	padding: 0;
	color: #acacac;
	text-transform: capitalize;
}

html[dir="rtl"] {
	--dir: rtl;
	--left: right;
	--right: left;
	--productListTranslate: -150%;
	--percentage: -100%;

	body {
		font-family: "Cairo", sans-serif;
	}
}

.link-company {
	.far,
	.fa,
	.fas {
		font-weight: 600;
		margin: 0 5px;
	}
	font-size: 14px;
	font-weight: 500;
}
.text-gray {
	color: #656565 !important;
}
// .text-black {
// 	color: #000000 !important;
// }
// .bg-navy {
// 	background: #324356;
// }

.is-active {
	border-bottom: 2px solid #1977fa;
}
.w-100 {
	width: 100%;
}
.birthday-picker {
	.react-calendar__navigation__arrow {
		font-size: 25px;
	}
	.react-calendar__month-view__days__day--weekend {
		color: #000;
	}
	.react-calendar__tile--now {
		background: #ffff7638;
	}
	.react-date-picker {
		width: 100% !important;
		.react-date-picker__wrapper {
			border: none !important;
			.react-date-picker__button {
				position: relative;
				z-index: 9;
				right: -10px;
				opacity: 0;
			}
		}
	}
}
.SingleDatePicker_picker {
	z-index: 99999999999999999999 !important;
}

.fixSelect .select {
	padding: 0 !important;
	border: 1px solid #aeaeae !important;
	background: #fff !important;
}

.team-management-tabs {
	.nav-tabs {
		display: flex;
		justify-content: center;
	}

	nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link {
		color: #000;
		border: none;
		padding: 1rem 2rem;
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		min-height: auto !important;
		background: transparent;
		color: #1777fb;
		border-bottom: 3px solid #1777fb;
		&::after {
			display: none;
		}
	}
}

.container-teamManagment {
	max-width: 1420px; /** 1320px*/
	margin-left: auto;
	margin-right: auto;
}

//Member List
.member-status {
	display: inline-block;
	text-align: center;
	border-radius: 5px;
	padding: 0.2rem 0.3rem;
	font-size: 12px;
	width: 70px;
	color: #fff;
}
.Active {
	background-color: $green;
}
.Pending,
.pending,
.Deactive {
	background-color: $red;
}
.Inactive {
	background-color: $orange;
}

//Branch Collpase
.branch-collapse {
	.branch-collabse-row {
		.col-sm-6 {
			padding: 0 auto !important;
			line-height: 0.5 !important;
		}
	}
	.button-collapse {
		background: #eee;
		border: 1px solid $gray-150;
	}
	.card-body {
		.head {
			padding: 0.5rem;
			p {
				font-size: 14px;
				&:first-child {
					color: #3f4f61;
				}
				&:last-child {
					color: $gray-300;
				}
			}
		}
	}
}

.modal-header {
	h5 {
		font-weight: 700;
		font-size: $text-body;
	}
}

.disableInput {
	input,
	.control-field {
		background-color: #e8e8e8 !important;
		color: #fff !important;
	}
}

// .table-management {
// 	thead {
// 		background-color: $dark-gray;
// 		th {
// 			border-color: $dark-gray;
// 		}
// 	}
// }
.team-management-tabs {
	button.btn-yellow:hover {
		background-color: #bf8d42;
		color: #ffffff;
	}
	.nav-tabs {
		justify-content: start;
		.nav-link {
			border: 0px;
		}
		.nav-link.active {
			border-top: 1px solid #dfdfdf;
			border-left: 1px solid #dfdfdf;
			border-right: 1px solid #dfdfdf;
		}
		.nav-link.active .product-build-list-item span {
			color: #cb9a51 !important;
			font-size: 15px !important;
			font-weight: 800 !important;
		}
	}
	.font-16 {
		font-size: 16px;
	}
	.manage-box {
		.member-head {
			border: 1px solid #d9d9da;
			border-top: 0px;
			border-bottom: 0px;
			button {
				padding: 9px 65px !important;
				font-size: 16px;
			}
		}
		.main-filter {
			border-bottom: 0px;
		}
	}
	.team-box {
		.button-collapse {
			background-color: #ebebeb;
			border-radius: 2px;
		}
		.card {
			border: 0 !important;
		}

		.fixHandleCreate.modal-dialog {
			position: absolute;
			left: 50%;
			top: 45%;
			transform: translate(-50%, -50%) !important;
		}
	}
}
.fixHandleCreate.modal-team.modal-dialog {
	position: absolute;
	left: 50%;
	top: 52%;
	transform: translate(-50%, -50%) !important;
}
.text-blue-dark {
	color: #0c3b5c !important;
}

.font-18 {
	font-size: 18px;
}
.modal-team {
	button.btn-yellow:hover {
		background-color: #bf8d42;
		color: #ffffff;
	}
	.form-check-modal {
		filter: invert(1%) hue-rotate(192deg) brightness(1.2);
	}
}
.interest-result {
	background-color: $gray-100;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// border: 1px solid #eae8e9;
	border-radius: 0.3125rem;
	height: 65px;
	.interest-data {
		line-height: 0.9rem;
		p {
			font-size: 13px !important;
			font-weight: 600;
		}
	}
	.dist {
		color: #acacac;
	}
	.fa-times {
		color: #000 !important;
		font-size: 15px !important;
	}
}
.interest {
	.tab-content {
		// min-height: 84vh;
		border: 1px solid #dfdfdf;
		border-top: 0 !important;
	}
	.nav {
		display: flex;
		flex-wrap: nowrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		.nav-item {
			min-height: 55px;
			width: 20%;
			text-align: center;
			margin-bottom: 0px !important;
			background: #ebebeb !important;
		}
		.nav-link.active {
			height: 55px !important;
		}
		.nav-link {
			height: 55px !important;
			border: 0.3px solid #dfdfdf;
			border-bottom: 0;
			border-radius: 0 !important;
		}
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-color: #fff !important;
		min-height: 55px !important;
		background-color: #fff !important;
		border-radius: 0 !important;
		border: 1px solid #dfdfdf !important;
		border-bottom: 0 !important;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active .product-build-list-item span {
		color: #cb9a51 !important;
		font-size: 18px;
		font-weight: 700;
	}

	.tab-icons {
		background-color: #ebebeb !important;
	}
	.line-height-1 {
		line-height: 1 !important;
	}
	.no-hotel {
		width: 100%;
		background: #f7f7f7;
		height: 350px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.3125rem;
		p {
			color: #979797;
			margin: 15px 0;
		}
	}
	.btn-yallow {
		background-color: #cb9a51 !important;
		color: #fff;
		font-size: 16px;
	}
	.select-hr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: #979797;
			font-size: 15px;
		}
		div {
			height: 1px;
			width: 88%;
			background: #dfdfdf;
		}
	}
}
@media (min-width: 800px) and (max-width: 1180px) {
	.product-build-list-item {
		font-size: 14px;
	}
	.interest .nav-tabs .nav-item.show .nav-link,
	.interest .nav-tabs .nav-link.active .product-build-list-item span {
		font-size: 15px;
	}
	.select-hr {
		p {
			font-size: 14px !important;
		}
		div {
			width: 87% !important;
		}
	}
}

.bg-nxt {
	background-color: #cb9a51;
	color: #ffffff;
	font-size: 16px;
}

.bg-nxt:hover {
	background-color: #bf8d42;
	color: #ffffff;
}

@media (min-width: 600px) {
	.dropdown-menu-lg {
		width: 320px;
	}
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600 !important;
}
.Dashboard {
	.card-body {
		padding: 1.25rem !important;
	}
	.card {
		background-color: #fff !important;
		background-clip: border-box;
		border: 1px solid rgba(0, 0, 0, 0.125) !important;
	}
}

ol,
ul {
	padding-left: 0;
}

.fixRoom {
	.input-group {
		padding: 0 !important;
		.control-field__body {
			background: #fff !important;
		}
	}
}

.room .table > :not(caption) > * > * {
	padding: 0.5rem !important;
}

.btn-primary {
	color: #fff;
	background-color: #1777fb !important;
	border-color: #1777fb !important;
}

.text-transform-DU {
	text-transform: capitalize;
	color: #000;
	font-size: 14px;
}

.disabled-calender {
	.font-12 {
		opacity: 0.4;
	}
}
.calendar-parent {
	input:disabled {
		background-color: #dcdcdcc9;
	}
}
