.custom-label {
	font-size: 0.75rem;
	font-weight: 500;
	color: #324356;
	margin-bottom: 0;
	width: 100%;
	text-align: var(--left);
	text-transform: uppercase;
	padding: 4px 5px;
}
.upload-icon {
	font-size: 50px;
}
.item_box {
	background-color: whitesmoke;
	border-radius: 6px;
	padding: 8px;
	margin: 8px 0;
	position: relative;
	.span-delete {
		position: absolute;
		right: 10px;
		color: #fff;
		background-color: red;
		width: 20px;
		height: 20px;
		border-radius: 40px;
		font-size: 14px;
		text-align: center;
	}
}
.title-form {
	font-size: 12px !important;
	color: gray;
}
.link-add {
	color: #1777fb;
	font-size: 14px;
}
.question-font {
	font-size: 14px;
	font-family: Roboto;
	padding: 10px 0;
	color: #324356;
	font-weight: 500;
}
.main-bar {
	.current {
		color: #007bff !important;
		font-weight: 600 !important;
	}
	.active {
		color: #007bff !important;
		font-weight: 600 !important;
	}
}
.confirmation {
	.title {
		font-size: 22px;
		font-weight: 500;
	}
	.sub-title {
		font-size: 16px;
		font-weight: 200;
	}
	.section-title {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 20px;
		border-bottom: 1px solid #9f9a9a;
	}
	.label {
		font-size: 18px;
		font-weight: 500;
		padding-bottom: 12px;
		color: #324356;
	}
}

.btn-light-new {
	background-color: #eae8e9 !important;
}
.btn-light-new:hover {
	background-color: #9f9fa7cf !important;
}
.fix-done {
	min-height: 65vh;
	img {
		padding-top: 5%;
		display: block;
		margin: auto;
	}
}
