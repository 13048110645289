/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Text Sizes - Class
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 8 through 72 {
	.text-#{$i} {
		font-size: $i / 16 + rem !important;
	}
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    04. Text Colors - Classes
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.black-text {
	color: $black !important;
}

.white-text {
	color: $white !important;
}

.text-success {
	color: $green !important;
}

.text-danger {
	color: $red !important;
}

.text-warning {
	color: $orange !important;
}

.gray-color {
	color: $gray-300 !important;
}

.dark-blue-color {
	color: $dark-blue !important;
}

.bg-dark-blue-color {
	background: $dark-blue !important;
}
.bg-blue-black {
	background: $blueBlack;
}
.text-blue-black {
	color: $blueBlack;
}
.bg-white-blue {
	background: $whiteBlue !important;
}

.text-gray-300 {
	color: $gray-300;
}

.bg-gray-100 {
	background: $gray-100;
}
.bg-transparent {
	background: transparent !important;
}
.main-color {
	background: #FBFAFF;
}
/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    05. Text Weights - Class
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.bold-text {
	font-weight: bold;
}

.bolder-text {
	font-weight: bolder;
}

.font-regular {
	font-weight: $font-weight-regular;
}

.font-medium {
	font-weight: $font-weight-medium;
}

.font-light {
	font-weight: $font-weight-light;
}

.font-bold {
	font-weight: $font-weight-bold;
}

.font14 {
	font-size: $font14;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    06. Text Sizes - Class
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.text-title {
	font-size: $text-title;
}

.text-title2 {
	font-size: $text-title2;
}

.text-title3 {
	font-size: $text-title3;
}
.text-title4 {
	font-size: $text-title4;
}

.text-subtitle {
	font-size: $text-subtitle;
}

.text-headline {
	font-size: $text-headline !important;
}

.text-body {
	font-size: $text-body !important;
}

.text-caption {
	font-size: $text-caption !important;
}

.text-caption2 {
	font-size: $text-captio2 !important;
}

.text-caption3 {
	font-size: $text-captio3 !important;
}

.btn-danger {
	background: $red !important;
	border: 0;
}

.btn-success {
	background: $green !important;
	border: $green;
}

.p-1-25 {
	// padding: 1rem !important;
	padding: 0.5rem !important;
}

textarea {
	resize: none;
}

.text-right {
	text-align: right !important;
	[dir="rtl"] & {
		text-align: left !important;
	}
}

.w-40 {
	width: 40% !important;
}

.vh-50 {
	height: 50vh !important;
}

.min-w-120 {
	min-width: 120px;
}

.font14 {
	font-size: 14px;
}
